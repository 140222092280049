var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-sheet",
    {
      staticClass: "pa-2",
      class: _vm.dragOver ? "dragOverBorder" : "noDragBorder",
      staticStyle: { cursor: "pointer" },
      on: {
        dragenter: _vm.setDragOverToTrue,
        dragleave: _vm.setDragOverToFalse,
        dragover: _vm.setDragOverToTrue,
        drop: _vm.dropFile,
        keypress: _vm.onKeyPress,
        click: function($event) {
          return _vm.$refs.fileUpload.click()
        }
      }
    },
    [
      _c("input", {
        ref: "fileUpload",
        staticStyle: { display: "none" },
        attrs: { type: "file", accept: _vm.acceptedFiles },
        on: { change: _vm.inputChange }
      }),
      _c(
        "v-row",
        {
          staticClass: "text-center title full-height",
          class: _vm.dragOver ? "vitrueGrey--text" : "white--text",
          attrs: { justify: "center", align: "center" }
        },
        [
          _c("v-col", [
            _c(
              "div",
              [
                !_vm.dragOver
                  ? _c(
                      "v-icon",
                      {
                        attrs: {
                          color: _vm.dragOver ? "vitrueGrey" : "white",
                          size: _vm.small ? 50 : 75
                        }
                      },
                      [_vm._v("mdi-cloud-upload-outline")]
                    )
                  : _vm._e(),
                _vm.dragOver
                  ? _c(
                      "v-icon",
                      {
                        attrs: {
                          color: _vm.dragOver ? "vitrueGrey" : "white",
                          size: _vm.small ? 50 : 75
                        }
                      },
                      [_vm._v("mdi-book-plus")]
                    )
                  : _vm._e()
              ],
              1
            ),
            !_vm.small
              ? _c("p", { class: _vm.extraInformationText ? "mb-4" : "mb-0" }, [
                  _vm._v(" " + _vm._s(_vm.$t("fileDropComponent.info")) + " ")
                ])
              : _vm._e(),
            _vm.extraInformationText && !_vm.small
              ? _c("p", { staticClass: "mb-0 text-body-2" }, [
                  _vm._v(" " + _vm._s(_vm.extraInformationText) + " ")
                ])
              : _vm._e(),
            _vm.maxFileSizeMb && !_vm.small
              ? _c("p", { staticClass: "text-caption" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("fileDropComponent.maxSizeTest", {
                          "0": _vm.maxFileSizeMb
                        })
                      ) +
                      " "
                  )
                ])
              : _vm._e()
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }